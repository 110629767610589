<template>
  <div id="addNotification" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >ASIGNACIÓN DE CUESTIONARIO</v-row
      >
      <v-container fluid class="addForms" style="margin-top: 2%">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <v-row>
              <!--Razon Social-->
              <label for="reason">Razón social</label>
              <select
                name="reason"
                id="reason"
                v-model="enterprise"
                @change="obtenerAreas()"
              >
                <option selected disabled value="0">Selecciona Razon</option>
                <option
                  v-for="reason in enterprises"
                  :key="reason.id"
                  v-bind:value="reason.id"
                >
                  {{ reason.razonSocial }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Area-->
              <label for="area">Área</label>
              <select
                name="area"
                id="area"
                v-model="area"
                @change="obtenerPuestos()"
              >
                <option selected disabled value="0">Selecciona Area</option>
                <option
                  v-for="area in areas"
                  :key="area.id"
                  v-bind:value="area.id"
                >
                  {{ area.nombreArea }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Puesto-->
              <label for="job">Puesto</label>
              <select
                name="job"
                id="job"
                v-model="job"
                @change="obtenerEmpleados()"
              >
                <option selected disabled value="0">Selecciona Puesto</option>
                <option v-for="job in jobs" :key="job.id" v-bind:value="job.id">
                  {{ job.nombrePuesto }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Empleados-->
              <label for="employee">Empleado</label>
              <select name="employee" id="employee" v-model="employee">
                <option selected disabled value="0">Selecciona Empleado</option>
                <option
                  v-for="employee in employees"
                  :key="employee.id"
                  v-bind:value="employee.id"
                >
                  {{ employee.nombre }} {{ employee.apellidoPaterno }}
                  {{ employee.apellidoMaterno }}
                </option>
              </select>
            </v-row>
            <!--GUIA DE REFERENCIA-->
            <v-row>
              <label for="guia">Cuestionario Ref.</label>
              <select name="guia" id="guia" v-model="guia">
                <option selected disabled value="0"
                  >Seleccione guía de referencia</option
                >
                <option selected value="1">1</option>
                <option selected value="2">2</option>
                <option selected value="3">3</option>
              </select>
            </v-row>
            <v-row>
              <!--Comunicado-->
              <label for="event">Comunicado</label>
              <input
                type="text"
                name="event"
                id="event"
                placeholder="Titulo del Comunicado"
                class="inputs"
                autocomplete="false"
                v-model="event"
                maxlength="20"
                @keypress="alfaNumerico($event)"
              />
              <br />
              <!--No delete target label-->
              <label></label>
              <textarea
                id="eventText"
                placeholder="Editor de texto..."
                v-model="eventText"
                @keypress="alfaNumerico($event)"
              ></textarea>
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="person">Envio</label>
              <input
                type="text"
                name="person"
                id="person"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="person"
                maxlength="20"
                disabled
              />
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="startDate">Fecha de Inicio</label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="startDate"
                maxlength="20"
                :min="fecha_minimo"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
            <v-row>
              <!--Envio-->
              <label for="finishDate">Fecha de Fin</label>
              <input
                type="date"
                name="finishDate"
                id="finishDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="finishDate"
                maxlength="20"
                :min="fecha_minimo"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/guia" id="backNotification">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de asignar el cuestionario?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="revisionUltimaAsignacion()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Asignación correcta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              confirmation = false;
              aux();
            "
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Alerta de meses-->
    <v-dialog v-model="alerta" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Pronta Asignación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="cancelarDuplicidad()"
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              alerta = false;
              guardar();
            "
            v-if="bandera == 0"
            >Aceptar</v-btn
          ><v-btn
            color="success"
            text
            @click="
              alerta = false;
              guardar2();
            "
            v-if="bandera == 1"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      guia: 0,
      machine: "",
      errors: [],
      questions: [],
      questionsAux: [],
      respuesta: "",
      titulo: "",
      advice: false,
      error: false,
      confirmation: false,
      enterprises: [],
      areas: [],
      jobs: [],
      employees: [],
      employeesEmpresa: [],
      sender: localStorage.empleadoIdGlobal,
      person: localStorage.nombreEmpleadoGlobal,
      enterprise: localStorage.empresaIdGlobal,
      event: "",
      eventText: "",
      area: 0,
      job: 0,
      employee: 0,
      finishDate: "",
      startDate: "",
      fecha_minimo: "",
      error: false,
      bandera: 0,
      fechaHoy: "",
      fechaProxima: "",
      alerta: false,
    };
  },
  methods: {
    cancelarDuplicidad() {
      this.questionsAux = [];
      this.alerta = false;
    },
    validacionFechas() {
      if (this.startDate != "" && this.finishDate == "")
        if (this.finishDate != "" && this.startDate == "")
          if (this.startDate != "" && this.finishDate != "") {
            //console.log("solo se lleno fecha inicial");
            //console.log("solo se lleno fecha final");
            //console.log("ambas fechas tienen valors");
            if (this.startDate > this.finishDate) {
              this.titulo = "Fechas Solicitadas";
              this.respuesta =
                "La fecha de inicio debe ser diferente, favor de corregirlo";
              this.error = true;
            }
          }
    },
    //alerta de la fecha ultima de asignacion
    revisionUltimaAsignacion() {
      this.advice = false;
      //guardar2() si bandera es 1
      //gurdar si bandera es 0
      var dias = 180; // 180 dias = 6 meses para alerta de guia
      //todas las encuestas respondidas
      this.questions.forEach((value) => {
        //la encuesta es por empleado en especifico
        if (this.employee != 0) {
          if (
            value.guiaReferencia == this.guia &&
            value.empresaId == this.enterprise &&
            value.empleadoId == this.employee
          ) {
            var fecha = new Date(value.fechaInicio);
            fecha.setDate(fecha.getDate() + dias);
            this.formatDate(fecha);
            if (this.fechaProxima > this.startDate) {
              this.questionsAux.push(value);
            }
          }
        }
        //asignacion es por puesto
        else if (this.employee == 0 && this.job != 0 && this.area == 0) {
          if (
            value.guiaReferencia == this.guia &&
            value.empresaId == this.enterprise &&
            value.puestoId == this.job
          ) {
            var fecha = new Date(value.fechaInicio);
            fecha.setDate(fecha.getDate() + dias);
            this.formatDate(fecha);
            if (this.fechaProxima > this.startDate) {
              this.questionsAux.push(value);
            }
          }
        }
        //asignacion es por area
        else if (this.employee == 0 && this.job == 0 && this.area != 0) {
          if (
            value.guiaReferencia == this.guia &&
            value.empresaId == this.enterprise &&
            value.areaId == this.area
          ) {
            var fecha = new Date(value.fechaInicio);
            fecha.setDate(fecha.getDate() + dias);
            this.formatDate(fecha);
            if (this.fechaProxima > this.startDate) {
              this.questionsAux.push(value);
            }
          }
        }
      });
      /*console.log("las encuestas que aun no cumplen los 6 meses");
      console.log(this.questionsAux);*/
      if (this.questionsAux.length > 0) {
        this.alerta = true;
        this.respuesta =
          "El cuestionario ya ha asignado en un lapso menor a 6 meses. ¿Esta seguro de volver asignarlo?.";
      } else {
        this.bandera == 0 ? this.guardar() : this.guardar2();
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      this.fechaProxima = [year, month, day].join("-");
      return [year, month, day].join("-");
    },
    //asigna cuestionario individual
    guardar() {
      this.advice = false;
      this.show = true;
      var empleadoAux = this.sender;
      //console.log("empleado aux", empleadoAux);
      //console.log(this.guia);
      axios
        .post(
          Server + "/cuestionariosGuiaUno",
          {
            EmpresaId: parseInt(this.enterprise),
            AreaId: parseInt(this.area),
            PuestoId: parseInt(this.job),
            EmpleadoId: parseInt(this.employee),
            TituloComunicado: this.event,
            DescripcionComunicado: this.eventText,
            EmpleadoEnviaId: parseInt(this.sender),
            FechaInicio: this.startDate,
            FechaFin: this.finishDate,
            GuiaReferencia: parseInt(this.guia),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.respuesta = "La encuesta fue asignada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          this.respuesta = "La encuesta no fue asignada";
          this.confirmation = true;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //asigna cuestionarios masivos
    guardar2() {
      this.advice = false;
      this.show = true;
      var empleadoAux = this.sender;
      //console.log(this.guia);
      axios
        .post(
          Server + "/cuestionariosGuiaUno/guia-dos-tres",
          {
            EmpresaId: parseInt(this.enterprise),
            AreaId: this.area == 0 ? 0 : parseInt(this.area),
            PuestoId: this.job == 0 ? 0 : parseInt(this.job),
            EmpleadoId: this.employee == 0 ? 0 : parseInt(this.employee),
            TituloComunicado: this.event,
            DescripcionComunicado: this.eventText,
            EmpleadoEnviaId: parseInt(this.sender == 0)
              ? 0
              : parseInt(this.sender),
            FechaInicio: this.startDate,
            FechaFin: this.finishDate,
            GuiaReferencia: parseInt(this.guia),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.respuesta = "La Encuesta fue asignada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.respuesta = "La encuesta no fue asignada";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function(evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function() {
      this.errors = [];
      if (this.enterprise == 0) {
        this.errors.push("Se debe seleccionar la razón social.");
      }
      if (this.sender == 0) {
        this.errors.push("Se debe especificar envio.");
      }
      if (this.event == "" || this.event == " ") {
        this.errors.push("Se debe especificar título de comunicado.");
      }
      if (this.eventText == "" || this.eventText == " ") {
        this.errors.push("Se debe especificar texto del comunicado.");
      }
      if (this.startDate == "" || this.startDate == " ") {
        this.errors.push("Se debe especificar fecha de inicio del comunicado.");
      }
      if (this.finishDate == "" || this.finishDate == " ") {
        this.errors.push("Se debe especificar fecha de fin del comunicado.");
      }
      if (this.guia == 0) {
        this.errors.push(
          "Se debe especificar referencia de guía del cuestionario."
        );
        if (this.errors.length == 0) {
          return (this.advice = true);
        } else {
          this.respuesta = "Por favor corrige el siguiente error:";
          this.titulo = "ASIGNACIÓN INCORRECTA";
          this.error = true;
        }
      }
      if (this.guia == 1) {
        if (this.area == 0) {
          this.errors.push("Se debe seleccionar el area.");
        }
        if (this.job == 0) {
          this.errors.push("Se debe seleccionar el puesto.");
        }
        if (this.employee == 0) {
          this.errors.push("Se debe seleccionar el empleado.");
        }
        if (this.errors.length == 0) {
          this.bandera = 0;
          return (this.advice = true);
        } else {
          this.respuesta = "Por favor corrige el siguiente error:";
          this.titulo = "ASIGNACIÓN INCORRECTA";
          this.error = true;
        }
      }
      //No es obligatorio elegir a quien puede ser empresa/area puesto
      else if (this.guia == 2 || this.guia == 3) {
        //console.log("guia" + this.guia);
        //La asignación será para todos los empleados de la empresa
        if (this.area == 0 && this.job == 0 && this.employee == 0) {
          if (this.employeesEmpresa.length > 0) {
            this.bandera = 1;
            this.advice = true;
          } else {
            this.titulo = "Error de asignación";
            this.respuesta =
              "Lo sentimos el area no tiene empleados para asignar el cuestionario";
            this.error = true;
          }
        }
        //la asignacion sera por area
        else if (this.area != 0 && this.job == 0 && this.employee == 0) {
          if (this.employeesEmpresa.length > 0) {
            this.bandera = 1;
            this.advice = true;
          } else {
            this.titulo = "Error de asignación";
            this.respuesta =
              "Lo sentimos el area no tiene empleados para asignar el cuestionario";
            this.error = true;
          }
        }
        //la asignacion sera por puesto
        else if (this.area != 0 && this.job != 0 && this.employee == 0) {
          if (this.employeesEmpresa.length > 0) {
            this.bandera = 1;
            this.advice = true;
          } else {
            this.titulo = "Error de asignación";
            this.respuesta =
              "Lo sentimos el puesto no tiene empleados para asignar el cuestionario";
            this.error = true;
          }
        } else {
          if (this.employee != 0) {
            this.bandera = 0;
            this.advice = true;
          }
        }
      }
    },
    aux() {
      this.advice = false;
      this.enterprise = 0;
      this.area = 0;
      this.job = 0;
      this.employee = 0;
      this.sender = "";
      this.event = "";
      this.eventText = "";
      this.person = "";
      this.startDate = "";
      this.finishDate = "";
      this.guia == 1
        ? this.$router.push("/guia")
        : this.guia == 2
        ? this.$router.push("/guiaII")
        : this.$router.push("/guiaIII");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreas() {
      this.areas = [];
      this.area = 0;
      this.job = 0;
      this.employee = 0;
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerPuestos() {
      this.jobs = [];
      this.employee = 0;
      this.job = 0;
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.enterprise &&
              value.areaReportaId == this.area
            ) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.obtenerEmpleadosArea();
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    todosEmpleadosEmpresa() {
      this.employeesEmpresa = [];
      this.show = true;
      axios
        .get(Server + "/empleados/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.employeesEmpresa = response.data;
          //console.log("empleados totales de empresa");
          //console.log(this.employeesEmpresa);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpleadosArea() {
      this.employeesEmpresa = [];
      this.show = true;
      axios
        .get(Server + "/empleados/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var todosEmpleados = response.data;
          todosEmpleados.forEach((element) => {
            if (
              element.empresaId == this.enterprise &&
              element.areaId == this.area
            ) {
              this.employeesEmpresa.push(element);
            }
          });
          //console.log("empleados del area");
          //console.log(this.employeesEmpresa);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
        });
    },
    obtenerEmpleados() {
      this.employees = [];
      this.employeesEmpresa = [];
      this.employee = 0;
      this.show = true;
      axios
        .get(Server + "/empleados", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var todosEmpleados = response.data;
          todosEmpleados.forEach((element) => {
            if (
              element.empresaId == this.enterprise &&
              element.puestoId == this.job &&
              element.areaId == this.area
            ) {
              this.employees.push(element);
              this.employeesEmpresa.push(element);
            }
          });
          //console.log("empleados totales de puesto");
          //console.log(this.employeesEmpresa);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    fechasMinimo() {
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //0 al 11
      _mes = _mes + 1; //de 1 al 12
      if (_mes < 10) {
        //agrega un 0
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString();
      }
      this.fecha_minimo = anio + "-" + mes + "-" + dia; // Nueva variable
      this.fechaHoy = anio + "-" + mes + "-" + dia;
    },
    cuestionariosExistentes() {
      this.questions = [];
      this.show = true;
      axios
        .get(Server + "/cuestionariosGuiaUno", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          /*response.data.forEach((element) => {
            if (element.fechaRespondido != null) {
              this.questions.push(element);
            }
          });*/
          this.questions = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.fechasMinimo();
    this.listar();
    this.cuestionariosExistentes();
    this.obtenerAreas();
    this.todosEmpleadosEmpresa();
  },
};
</script>
